import React from 'react';

import styles from './Button.module.css';

const button = (props) => (
    <button
        disabled={props.disabled}
        style={props.manualStyles}
        className={[styles.Button, styles[props.btnType], styles[props.btnClassName], styles[props.additionalClass]].join(' ')}
        onClick={props.clicked}>{props.children}</button>
);

export default button;