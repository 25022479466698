import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  items: null,
  totalItem: 0,
  error: false,
  building: false,
  flightId: "",
};

const addItem = (state, action) => {
  const updatedItem = { [action.itemName]: state.items[action.itemName] + 1 };
  const updatedItems = updateObject(state.items, updatedItem);
  const updatedState = {
    items: updatedItems,
    totalItem: state.totalItem + 1,
    building: true
  };
  return updateObject(state, updatedState);
};

const removeItem = (state, action) => {
  const updatedItm = {
    [action.itemName]: state.items[action.itemName] - 1
  };
  const updatedItms = updateObject(state.items, updatedItm);
  const updatedSt = {
    items: updatedItms,
    totalItem: state.totalItem - 1,
    building: true
  };
  return updateObject(state, updatedSt);
};

const setItems = (state, action) => {
  return updateObject(state, {
    items: {
      trolley: action.items.trolley,
      luggage: action.items.luggage,
      backpack: action.items.backpack,
      foc: action.items.foc,
      a4: action.items.a4,
      coat: action.items.coat
    },
    totalItem: 0,
    error: false,
    building: false
  });
};

const fetchItemsFailed = (state, action) => {
  return updateObject(state, { error: true });
};

const addFlight = (state, action) => {
  return updateObject(state, { flightId: action.flightId });
};

const nextPassenger = (state, action) => {
  return updateObject(state, {
    items: action.items,
    totalItem: action.totalItem
  });
};

const resetPassenger = (state, action) => {
  return updateObject(state, {
    items: action.items,
    totalItem: action.totalItem,
    passengerStartTimeStamp: new Date().getTime()
  });
};

const saveFlight = (state, action) => {
  const now = new Date()

  return updateObject(state, {
    items: action.items,
    totalItem: action.totalItem,
    endFlightTimeStamp: now.getTime(),
    formattedEndFlightTimeStamp: now.toLocaleDateString() + ' ' + now.toLocaleTimeString()
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ITEM:
      return addItem(state, action);
    case actionTypes.REMOVE_ITEM:
      return removeItem(state, action);
    case actionTypes.SET_ITEMS:
      return setItems(state, action);
    case actionTypes.ADD_FLIGHT:
      return addFlight(state, action);
    case actionTypes.PROCEED_TO_NEXT_PASSENGER_SUCCESS:
      return nextPassenger(state, action);
    case actionTypes.RESET_PASSENGER:
      return resetPassenger(state, action);
    case actionTypes.SAVE_FLIGHT:
      return saveFlight(state, action);
    case actionTypes.FETCH_ITEMS_FAILED:
      return fetchItemsFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
