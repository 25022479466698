import React, { Component } from "react";
// import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import styles from "./Flight.module.css";
import inputStyles from "../../components/UI/Input/Input.module.css";
import axios from "../../axios-cob";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";
import { updateObject, checkValidity } from "../../shared/utility";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

class Flight extends Component {
  state = {
    flightForm: {
      flightNumber: {
        elementType: "input",
        label: "Flight Number",
        classes: "UpperCase",
        elementConfig: {
          type: "text",
          placeholder: "Enter Flight Number..."
        },
        value: "",
        validation: {
          required: false
        },
        valid: false,
        touched: false
      },
      from: {
        elementType: "input",
        label: "From",
        classes: "UpperCase",
        elementConfig: {
          type: "text",
          placeholder: "Departure Airport/City"
        },
        value: "",
        validation: {
          required: false
        },
        valid: false,
        touched: false
      },
      to: {
        elementType: "input",
        label: "To",
        classes: "UpperCase",
        elementConfig: {
          type: "text",
          placeholder: "Destination Airport/City"
        },
        value: "",
        validation: {
          required: false
          //   minLength: 5,
          //   maxLength: 5,
          //   isNumeric: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: false,
    date: moment()
  };

  flightStartHandler = event => {
    event.preventDefault();

    const formData = {};
    for (let formElementIdentifier in this.state.flightForm) {
      formData[formElementIdentifier] = this.state.flightForm[
        formElementIdentifier
      ].value;
    }
    // Add Flight Date to formData object
    const flightDate = { flightDate: this.state.date.format("DD/MM/YYYY") };
    Object.assign(formData, flightDate);

    // Add Flight Boarding Start Timestamp to formData Object
    const now = new Date()
    formData['flightStartTimeStamp'] = now.getTime()
    formData['formattedFlightStartTimeStamp'] = now.toLocaleDateString() + ' ' + now.toLocaleTimeString()

    const flight = {
      flightData: formData,
      userId: this.props.userId
    };

    this.props.onProceedToDataEntry(flight, this.props.token);

    this.props.history.replace("/dataEntry");
  };

  inputChangedHandler = (event, inputIdentifier) => {
    const updatedFormElement = updateObject(
      this.state.flightForm[inputIdentifier],
      {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.flightForm[inputIdentifier].validation
        ),
        touched: true
      }
    );
    const updatedflightForm = updateObject(this.state.flightForm, {
      [inputIdentifier]: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedflightForm) {
      formIsValid = updatedflightForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ flightForm: updatedflightForm, formIsValid: formIsValid });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.flightForm) {
      formElementsArray.push({
        id: key,
        config: this.state.flightForm[key]
      });
    }
    let form = (
      <form onSubmit={this.flightStartHandler}>
        {formElementsArray.map(formElement => (
          <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            label={formElement.config.label}
            value={formElement.config.value}
            classes={formElement.config.classes}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={event => this.inputChangedHandler(event, formElement.id)}
          />
        ))}

        <div className={inputStyles.Input} style={{ textAlign: "left" }}>
          <label className={inputStyles.Label}>Flight Date</label>
          <SingleDatePicker
            date={this.state.date}
            onDateChange={date => this.setState({ date })}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            displayFormat="DD/MM/YYYY"
            showClearDate={true}
            numberOfMonths={1}
            openDirection="up"
            hideKeyboardShortcutsPanel={true}
            firstDayOfWeek={1}
            block={true}
            isOutsideRange={() => false}
          />
        </div>

        <Button
          btnType="Success"
          btnClassName="Button-block"
          additionalClass="Button-lg"
          disabled={!this.state.formIsValid}
        >
          Proceed to Data Entry
        </Button>
      </form>
    );

    return (
      <div className={styles.FlightData}>
        <h1 className={styles.Title}>Enter Flight Information</h1>
        <div className={styles.UpperCase}>{form}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    userId: state.auth.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onProceedToDataEntry: (flightData, token) =>
      dispatch(actions.proceedToDataEntry(flightData, token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Flight, axios));
