import * as actionTypes from "./actionTypes";
import axios from "../../axios-cob";

export const addItem = ( name ) => {
    return {
        type: actionTypes.ADD_ITEM,
        itemName: name
    };
};

export const removeItem = ( name ) => {
    return {
        type: actionTypes.REMOVE_ITEM,
        itemName: name
    };
};

export const setItems = ( items ) => {
    return {
        type: actionTypes.SET_ITEMS,
        items: items
    };
};

export const fetchItemsFailed = () => {
    return {
        type: actionTypes.FETCH_ITEMS_FAILED
    };
};

export const initItems = () => {
    return dispatch => {
        axios.get( 'https://cob-messungen-2ed6b-default-rtdb.europe-west1.firebasedatabase.app/items.json' )
            .then( response => {
               dispatch(setItems(response.data));
            } )
            .catch( error => {
                dispatch(fetchItemsFailed());
            } );
    };
};

export const addFlight = () => {
  return {
    type: actionTypes.ADD_FLIGHT
  };
};

export const resetPassenger = () => {
  return {
    type: actionTypes.RESET_PASSENGER,
    items: {
      trolley: 0,
      luggage: 0,
      backpack: 0,
      foc: 0,
      a4: 0,
      coat: 0
    },
    totalItem: 0
  };
};

export const proceedToNextPassengerSuccess = (id, passenger) => {
  return {
    type: actionTypes.PROCEED_TO_NEXT_PASSENGER_SUCCESS,
    passengerId: id,
    passenger: passenger,
    items: {
      trolley: 0,
      luggage: 0,
      backpack: 0,
      foc: 0,
      a4: 0,
      coat: 0
    },
    totalItem: 0
  };
};

export const proceedToNextPassenger = (passenger, flightId, token) => {
  return dispatch => {
    axios
      .post(
        "/flights/" + flightId + "/passnegers.json?auth=" + token,
        passenger
      )
      .then(response => {
        dispatch(proceedToNextPassengerSuccess(response.data.name, passenger));
      })
      .catch(error => {
        console.log(error);
        // dispatch( proceedToNextPassengerFail( error ) );
      });
  };
};

export const saveFlightSuccess = (id, passenger) => {
  return {
    type: actionTypes.SAVE_FLIGHT,
    passengerId: id,
    passenger: passenger,
    items: {
      trolley: 0,
      luggage: 0,
      backpack: 0,
      foc: 0,
      a4: 0,
      coat: 0
    },
    totalItem: 0
  };
};

export const saveFlight = (passenger, flightId, token) => {
  return dispatch => {
    for (const pass in passenger) {
        axios
          .post(
            "/flights/" + flightId + "/passnegers.json?auth=" + token,
            passenger[pass]
          )
          .then(response => {
            dispatch(saveFlightSuccess(response.data.name, passenger[pass]));
          })
          .catch(error => {
            // dispatch( saveFlightFail( error ) );
          });
    }
  };


  // return dispatch => {
  //   axios
  //     .post(
  //       "/flights/" + flightId + "/passnegers.json?auth=" + token,
  //       passenger
  //     )
  //     .then(response => {
  //       dispatch(saveFlightSuccess(response.data.name, passenger));
  //     })
  //     .catch(error => {
  //       // dispatch( saveFlightFail( error ) );
  //     });
  // };
};

export const continueFlightDataEntry = flightId => {
  return {
    type: actionTypes.ADD_FLIGHT,
    flightId: flightId
  };
};
