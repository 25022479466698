export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_ITEMS = 'SET_ITEMS';
export const FETCH_ITEMS_FAILED = 'FETCH_ITEMS_FAILED';

export const ADD_FLIGHT = 'ADD_FLIGHT';
export const REMOVE_FLIGHT = 'REMOVE_FLIGHT';

export const PROCEED_TO_DATA_ENTRY_SUCCESS = 'PROCEED_TO_DATA_ENTRY_SUCCESS';

export const PROCEED_TO_NEXT_PASSENGER_SUCCESS = 'PROCEED_TO_NEXT_PASSENGER_SUCCESS';
export const RESET_PASSENGER = 'RESET_PASSENGER';
export const SAVE_FLIGHT = 'SAVE_FLIGHT';

export const FETCH_FLIGHTS_SUCCESS = 'FETCH_FLIGHTS_SUCCESS';
export const FETCH_FLIGHTS_START = 'FETCH_FLIGHTS_START';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';