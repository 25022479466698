import * as actionTypes from "./actionTypes";
import axios from "../../axios-cob";

export const proceedToDataEntrySuccess = (id, flightData) => {
  return {
    type: actionTypes.ADD_FLIGHT,
    flightId: id,
    flightData: flightData
  };
};

export const fetchFlightsSuccess = ( flights ) => {
  return {
      type: actionTypes.FETCH_FLIGHTS_SUCCESS,
      flights: flights
  };
};

export const proceedToDataEntry = (flightData, token) => {
  return dispatch => {
    axios
      .post("/flights.json?auth=" + token, flightData)
      .then(response => {
        dispatch(proceedToDataEntrySuccess(response.data.name, flightData));
      })
      .catch(error => {
        // dispatch( proceedToDataEntryFail( error ) );
      });
  };
};

export const removeFlight = (flightId, token) => {
  const queryParams = '?auth=' + token;

  return dispatch => {
    axios.delete(`/flights/${flightId}.json` + queryParams)
      .then(response => {
        axios.get('/flights.json' + queryParams)
          .then(res => {
            const fetchedFlights = [];
            for (let key in res.data) {
              fetchedFlights.push({
                ...res.data[key],
                id: key
              });
            }
            dispatch(fetchFlightsSuccess(fetchedFlights));
          })
      })
      .catch(error => {
        console.log(error)
      });
  }
}
