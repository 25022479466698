import * as actionTypes from './actionTypes';
import axios from '../../axios-cob';

export const fetchFlightsSuccess = ( flights ) => {
    return {
        type: actionTypes.FETCH_FLIGHTS_SUCCESS,
        flights: flights
    };
};

export const fetchFlightsStart = () => {
    return {
        type: actionTypes.FETCH_FLIGHTS_START
    };
};

export const fetchFlights = (token) => {
    return dispatch => {
        dispatch(fetchFlightsStart());
        const queryParams = '?auth=' + token;
        axios.get( '/flights.json' + queryParams)
            .then( res => {
                const fetchedFlights = [];
                for ( let key in res.data ) {
                    fetchedFlights.push( {
                        ...res.data[key],
                        id: key
                    } );
                }
                dispatch(fetchFlightsSuccess(fetchedFlights));
            } )
            .catch( err => {
        //         dispatch(fetchFlightssFail(err));
            } );
    };
};