import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    flights: [],
    loading: false,
    // purchased: false
};

const fetchFlightsStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchFlightsSuccess = (state, action) => {
    return updateObject(state, {
        flights: action.flights,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FLIGHTS_START: return fetchFlightsStart( state, action );
        case actionTypes.FETCH_FLIGHTS_SUCCESS: return fetchFlightsSuccess(state, action);
        // case actionTypes.FETCH_FLIGHTS_FAIL: return fetchFlightsFail( state, action );
        default: return state;
    }
};

export default reducer;