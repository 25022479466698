import React from 'react';

// import Logo from '../../assets/images/logo.png';
import styles from './Logo.module.css';

const logo = (props) => (
    <div className={styles.Logo} style={{height: props.height}}>
        {/*<img src={Logo} alt="Lufthansa Group" />*/}
        Baggage Process
    </div>
);

export default logo;